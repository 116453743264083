import api from "@/base/utils/request";

// 获取分类列表
export const CompanyCategory = data => {
  return api({
    url: "/admin/cyc/companyCategory/index2",
    method: "post",
    data
  });
};
// 新增子分类
export const addChildren = data => {
  return api({
    url: "/admin/cyc/companyCategory/addChildren",
    method: "post",
    data
  });
};
// 保存排序
export const saveSort = data => {
  return api({
    url: "/admin/cyc/companyCategory/saveSort",
    method: "post",
    data
  });
};
// 删除分类
export const deleteCategory = data => {
  return api({
    url: "/admin/cyc/companyCategory/del",
    method: "post",
    data
  });
};
// 保存分类
export const saveCategory = data => {
  return api({
    url: "/admin/cyc/companyCategory/save",
    method: "post",
    data
  });
};
// 获取分类选择列表
export const CategoryList = data => {
  return api({
    url: "/admin/cyc/CompanyCategory/all",
    method: "post",
    data
  });
};
// 转移企业数据
export const moveCategory = data => {
  return api({
    url: "/admin/cyc/companyCategory/move",
    method: "post",
    data
  });
};

// 更新显示
export const updateShow = data => {
  return api({
    url: "/admin/cyc/CompanyCategory/updateShow",
    method: "post",
    data
  });
};