<template>
  <el-dialog
    :title="form.id ? '编辑' : '添加'"
    :visible.sync="openDialog"
    width="500px"
    :show-close="true"
    class="dialog-vertical"
    @close="cancel('CategoryName')"
    @open="openDialogFn"
  >
    <el-form
      size="medium"
      :model="form"
      :rules="rules"
      ref="CategoryName"
      label-width="95px"
      v-loading="loading"
      @submit.native.prevent
    >
      <el-form-item label="分类名称：" prop="name">
        <el-input
          type="text"
          style="width: 75%"
          v-model="form.name"
          placeholder="请输入分类名称"
        ></el-input>
      </el-form-item>
      <el-form-item label="上级分类：" prop="pid">
        <el-cascader
          v-model="categoryIds"
          :options="categoryOptions"
          :props="{
            checkStrictly: true,
            children: '_children',
            value: 'id',
            label: 'name',
          }"
          clearable
        ></el-cascader>
      </el-form-item>
      <el-form-item label="显示：" prop="is_show">
        <el-radio-group v-model="form.is_show">
          <el-radio :label="1">显示</el-radio>
          <el-radio :label="0">不显示</el-radio>
        </el-radio-group>
      </el-form-item>
    </el-form>
    <span slot="footer">
      <el-button @click="openDialog = false">取消</el-button>
      <el-button
        type="primary"
        @click="submit('CategoryName')"
        :loading="saveLoading"
        >确定</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import {
  saveCategory,
  CompanyCategory,
} from '../../api/classify/classify-new-list'
export default {
  model: {
    prop: 'open',
    event: 'closeDialog',
  },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    currentRow: {
      type: Object,
    },
    updateList: {
      type: Function,
    },
  },
  data() {
    return {
      loading: false,
      rules: {
        name: [{ required: true, trigger: 'blur', message: '请输入分类名称' }],
        pid: [{ required: true, trigger: 'blur', message: '请选择上级分类' }],
        is_show: [{ required: true, trigger: 'blur', message: '请选择' }],
      },
      saveLoading: false,
      form: {
        id: 0,
        name: '',
        is_show: 1,
        pid: 0,
      },
      categoryIds: '',
      // 只显示到二级分类选项
      categoryOptions: [],
    }
  },
  methods: {
    // 保存
    submit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          // 上级分类是 选择的分类值是数组最后一个
          this.form.pid = this.categoryIds[this.categoryIds.length - 1]

          console.log(this.form, this.categoryIds)

          this.saveLoading = true
          saveCategory(this.form)
            .then((res) => {
              this.updateList()
              this.$refs[formName].resetFields()
              this.saveLoading = false
              this.openDialog = false
            })
            .catch((err) => {
              this.saveLoading = false
            })
        } else {
          return false
        }
      })
    },
    // 取消
    cancel(formName) {
      this.openDialog = false
      this.$refs[formName].resetFields()
    },

    openDialogFn() {
      this.getCompanyCategory()
    },
    // 获取分类列表
    getCompanyCategory() {
      this.loading = true
      CompanyCategory()
        .then((res) => {
          let Coptions = [{ id: 0, name: '无', pid: 0 }, ...res.data]
          this.form = JSON.parse(JSON.stringify(this.$props.currentRow))

          console.log(this.form)
          if (this.form.level_text === '三级') {
            Coptions.forEach((item) => {
              if (item._children && item._children.length) {
                item._children.forEach((item2) => {
                  if (item2.id === this.form.pid) {
                    this.categoryIds = [item2.pid, this.form.pid]
                  }
                })
              }
            })
          } else {
            this.categoryIds = [this.form.pid]
          }
          console.log(Coptions)
          console.log(this.categoryIds)
          // 只显示到二级分类选项
          this.categoryOptions = Coptions.map((item) => {
            // console.log(item)
            if (item._children && item._children.length) {
              item._children.forEach((item2) => {
                this.$delete(item2, '_children')
              })
            }
            return item
          })
          console.log(this.categoryOptions, 123123)

          this.loading = false
        })
        .catch((err) => {
          this.loading = false
        })
    },
  },
  computed: {
    openDialog: {
      get() {
        return this.open
      },
      set(val) {
        this.$emit('closeDialog', val)
      },
    },
  },
}
</script>

<style></style>
