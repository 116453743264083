<template>
  <div>
    <div class="body">
      <el-button
        style="margin-bottom: 20px"
        type="primary"
        size="small"
        @click="handleAddCategory"
        >添加分类
      </el-button>
      <div class="list-content">
        <el-table
          row-key="id"
          :data="tbody"
          id="sortTable"
          class="thead-light"
          :tree-props="{ children: '_children' }"
          stripe
          style="width: 100%"
          v-loading="loading"
          @sort-change="sortChange"
          sortable="custom"
        >
          <!-- 操作 -->
          <el-table-column label="操作" width="240" fixed="right">
            <template slot-scope="scope">
              <el-button-group>
                <!-- 最多支持三级分类  -->
                <el-button
                  :disabled="scope.row.level_text === '三级'"
                  type="text"
                  size="small"
                  @click="addChildRow(scope.row)"
                >
                  添加子类
                </el-button>
                <el-button type="text" size="small" @click="editRow(scope.row)">
                  编辑
                </el-button>
                <el-button
                  type="text"
                  size="small"
                  @click="transferRow(scope.row)"
                >
                  转移
                </el-button>
                <el-button
                  type="text"
                  size="small"
                  @click="deleteRow(scope.row)"
                >
                  删除</el-button
                >
              </el-button-group>
            </template>
          </el-table-column>
          <template v-for="(th, index) in thead">
            <el-table-column
              :prop="th.prop"
              :label="th.label"
              :width="th.width || ''"
              :key="index"
              :sortable="th.sortable"
            >
              <template slot-scope="scope">
                <!-- 排序 -->
                <span v-if="th.prop === 'sort'"></span>

                <!-- 显示 -->
                <el-checkbox
                  v-else-if="th.prop === 'is_show'"
                  v-model="scope.row[th.prop]"
                  :true-label="1"
                  :false-label="0"
                  @change="changeStatus(scope.row, th.prop)"
                ></el-checkbox>
                <!-- 普通 -->
                <span v-else-if="th.prop === 'is_on_stock'">{{
                  scope.row[th.prop] === 1 ? "已上市" : "未上市"
                }}</span>

                <span v-else>{{ scope.row[th.prop] | placeholder }}</span>
              </template>
            </el-table-column>
          </template>
        </el-table>
      </div>
      <Pagination
        :total="pageData.total"
        :page="pageData.current_page"
        :change="changPage"
      />

      <!-- 添加一级分类 -->
      <AddCategory
        v-model="openAddCategory"
        :currentRow="currentRow"
        :updateList="reflashList"
      />
      <!-- 添加子分类 -->
      <AddCategoryChild
        v-model="openAddCategoryChild"
        :currentRow="currentRow"
        :updateList="reflashList"
      />
      <TransferClassify
        v-model="openTransferDialog"
        :Data="TransferData"
        :categoryList="tbody"
        :updateData.sync="TransferData"
        :updateList="reflashList"
      />
    </div>
  </div>
</template>

<script>
import Pagination from "@/base/components/Default/Pagination";

import AddCategory from "../../../components/Classify/AddCategory";
import AddCategoryChild from "../../../components/Classify/AddCategoryChild";
import Sortable from "sortablejs/modular/sortable.core.esm.js";

import {
  CompanyCategory,
  addChildren,
  saveSort,
  deleteCategory,
  saveCategory,
  CategoryList,
  updateShow,
} from "../../../api/classify/classify-new-list";

import TransferClassify from "../../../components/Classify/TransferClassify.vue";
export default {
  data() {
    return {
      tabArr: [],
      loading: false, //加载
      thead: [
        { label: "拖拽排序", prop: "sort",width:80},
        { label: "分类名称", prop: "name" },
        { label: "分类层级", prop: "level_text" },
        { label: "企业数量", prop: "content_count", sortable: "custom" },
        { label: "显示", prop: "is_show", width: 90 },
      ],
      //筛选对象
      filterForm: {
        page_size: 15, //每页多少条数据
      },
      pageData: {},
      tbody: [],
      // 新增编辑弹窗数据
      CategoryData: {
        id: 0,
        name: "",
      },
      // 转移弹窗数据
      TransferData: {
        name: "",
        del_category_id: "",
        merge_category_id: "",
      },
      validator: {
        type: "integer",
        min: 0,
        message: "最小不能低于0的整数",
        trigger: "blur",
      },
      openAddDialog: false,
      openTransferDialog: false,
      // 新增一级分类，
      openAddCategory: false,
      // 新增子分类弹窗
      openAddCategoryChild: false,
      currentRow: {},
      // 将树形数据转换成平铺数据
      lineTbody: [],
    };
  },
  methods: {
    //初始化拖拽表格
    initSortable() {
      let that = this;
      this.$nextTick(() => {
        const sortTable = document.querySelector(
          "#sortTable .el-table__body>tbody"
        );
        console.log(sortTable);
        this.$sortTable = Sortable.create(sortTable, {
          animation: 150,
          ghostClass: "sortable-ghost",
          // 拖拽移动的时候
          onMove: function ({ dragged, related }) {
            // 筛选过的数据不能排序
            if (that.filterForm && that.filterForm.order_by) {
              return false;
            } else {
              console.log(that.lineTbody);
              // 对了，树数据中不管是哪一层，都一定要有level字段表示当前是第几层哦
              /*
              evt.dragged; // 被拖拽的对象
              evt.related; // 被替换的对象
             */

              // 被拖拽的两个分类必须是同级的，并且是同一个父分类
              let draggedRow = that.lineTbody[dragged.rowIndex];
              let relatedRow = that.lineTbody[related.rowIndex];
              if (
                draggedRow.level_text === relatedRow.level_text &&
                draggedRow.pid === relatedRow.pid
              ) {
                return true;
              } else {
                return false;
              }
            }
          },
          onEnd: (event) => {
            console.log(event.oldIndex, event.newIndex, event);
            // 没有移动位置
            if (event.oldIndex === event.newIndex) {
              return;
            }
            this.loading = true;
            // 拿到拖拽的item
            const oldItem = this.lineTbody[event.oldIndex];
            // 删除原本位置
            this.lineTbody.splice(event.oldIndex, 1);
            // 添加到新的位置
            this.lineTbody.splice(event.newIndex, 0, oldItem);
            console.log(oldItem);
            let ids = this.lineTbody
              .filter((item) => {
                if (item.pid === oldItem.pid) {
                  return true;
                } else {
                  return false;
                }
              })
              .map((item) => {
                return item.id;
              });
            console.log(ids);

            //保存拖拽排序
            this.saveCategorySort(ids);
          },
        });
        //阻止火狐拖拽新建新页面
        document.body.addEventListener("drop", this.preventDrop, false);
      });
    },
    // 获取分类列表
    getCompanyCategory(pageData) {
      this.loading = true;
      CompanyCategory(pageData)
        .then((res) => {
          const { data } = res;
          this.tbody = data;
          this.pageData = data;
          this.lineTbody = this.treeToTile(data);

          console.log(this.lineTbody, 123);
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
        });
    },

    // 新增行业类型
    handleAddCategory() {
      this.currentRow = { id: 0, name: "", pid: 0, is_show: 1 };
      this.openAddCategory = true;
    },
    // 点击列表删除
    deleteRow(row) {
      if (row.content_count > 0) {
        this.$msgbox
          .confirm("删除前请先将分类下的数据转移", "提示", {
            type: "info",
            confirmButtonText: "仍要删除",
            cancelButtonText: "取消",
          })
          .then((res) => {
            this.handleDelete({ id: row.id });
          })
          .catch((err) => {});
      } else if (row._childre && row._children.length) {
        this.$msgbox
          .confirm(
            "当前分类下有子分类，删除时子分类也全部删除，是否删除？",
            "提示",
            {
              type: "error",
            }
          )
          .then((res) => {
            this.handleDelete({ id: row.id });
          })
          .catch((err) => {});
      } else {
        this.$msgbox
          .confirm("确认要将该分类删除吗？删除后无法恢复。", "提示", {
            type: "error",
          })
          .then((res) => {
            this.handleDelete({ id: row.id });
          })
          .catch((err) => {});
      }
    },
    // 数据删除
    handleDelete(id) {
      this.loading = true;
      deleteCategory(id)
        .then((res) => {
          this.$message.success(res.msg);
          this.reflashList();
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    //点击转移
    transferRow(row) {
      this.TransferData.name = row.name;
      this.TransferData.del_category_id = row.id;
      this.openTransferDialog = true;
    },
    // 点击编辑
    editRow(row) {
      this.currentRow = row;
      this.openAddCategory = true;
    },
    // 添加子集分类
    addChildRow(row) {
      console.log(row);
      this.currentRow = row;
      this.openAddCategoryChild = true;
    },
    //表格排序
    sortChange(column) {
      if (this.filterForm.is_desc) {
        // 默认排序
        this.$delete(this.filterForm, "is_desc");
        this.$delete(this.filterForm, "order_by");
      } else {
        if (this.filterForm.is_desc === 0) {
          // 倒序
          this.filterForm.is_desc = 1;
        } else {
          // 正序
          this.filterForm.is_desc = 0;
        }
        this.filterForm.order_by = column.prop;
      }
      this.getCompanyCategory(this.filterForm);
    },
    // 刷新列表
    reflashList() {
      this.getCompanyCategory({
        page: this.pageData.current_page,
        ...this.filterForm,
      });
    },
    // 更新显示状态
    changeStatus(e, key) {
      console.log(e);
      const data = {};
      data.id = e.id;
      data[key] = e[key];
      this.loading = true;
      if (key === "is_show") {
        // 更新显示状态
        updateShow(data)
          .then((res) => {
            this.$message.success(res.msg);
            this.reflashList();
          })
          .catch(() => {
            this.reflashList();
          });
      }
    },
    // 分页查询
    changPage(e) {
      console.log(e);
      this.filterForm.page_size = e.page_size;
      const pageData = { ...this.filterForm, ...e };
      this.getCompanyCategory(pageData);
    },
    //销毁拖拽表格
    destroySortable() {
      if (this.$sortTable) this.$sortTable.destroy();
      document.body.removeEventListener("drop", this.preventDrop, false);
    },

    // 将树数据转化为平铺数据
    treeToTile(treeData, childKey = "_children") {
      let arr = [];
      const expanded = (data) => {
        if (data && data.length > 0) {
          data
            .filter((d) => d)
            .forEach((e) => {
              arr.push(e);
              expanded(e[childKey] || []);
            });
        }
      };
      expanded(treeData);
      return arr;
    },
    // 保存拖拽排序
    saveCategorySort(ids) {
      saveSort({ ids }).then((res) => {
        this.$message.success("保存顺序成功");
        this.reflashList();
      });
    },
  },
  created() {
    this.getCompanyCategory(this.filterForm); //获取列表数据
  },
  mounted() {
    //初始化拖拽表格
    this.initSortable();
  },
  beforeDestroy() {
    //销毁拖拽表格
    this.destroySortable();
  },
  components: {
    Pagination,
    TransferClassify,
    AddCategory,
    AddCategoryChild,
  },
};
</script>

<style lang="scss" scoped>
.container {
  .body {
    .err-box {
      ::v-deep.el-input__inner {
        color: red;
      }
    }

    .tool-tip {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
    }

    .status-dot {
      height: 6px;
      width: 6px;
      border-radius: 50%;
      margin-right: 5px;
      display: inline-block;
      vertical-align: middle;
    }

    .green {
      background-color: rgba(19, 206, 102, 1);
    }

    .yellow {
      background-color: rgba(255, 203, 44, 1);
    }
  }
}
</style>
